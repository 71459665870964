<template>
  <el-dialog class="yt-dialog" :title="title" :visible.sync="visible" :close-on-click-modal="false" :before-close="close" @closed="closed">
    <ul>
      <li>
        <span><b style="color: #ff0000">*</b>课程学习百分比设置(%)</span>
        <el-slider v-model="formData.passProgress" show-input />
      </li>
      <li>
        <div style="display: flex;">
          <b style="color: #ff0000">*</b>
          测试及格分设置
        </div>
        <el-input-number style="margin-top: 12px" :controls="false" :max="100" :min="0.1" :precision="1" v-model="formData.passExamScore" />
        <template v-if="formData.courseIds.length === 1">
          <el-button type="primary" size="small" style="width: 180px; margin-left: 20px" @click="getCoursePassScore">使用课程考试及格分</el-button>
        </template>
      </li>
      <li style="display: flex;flex-direction: column">
        <span><b style="color: #ff0000">*</b>测试次数设置(最多5次)</span>
        <el-input-number style="margin-top: 12px" :controls="false" :max="5" :min="0" v-model="formData.examMaxCount" />
      </li>
    </ul>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button class="button" type="primary" :loading="loading" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import learn from '@api/learnTask'
import courseApi from '@api/course'
export default {
  name: 'UpdateTask',
  data() {
    return {
      visible: false,
      loading: false,
      title: '修改学习完成任务条件',
      userId: null,
      formData: {
        courseIds: [],
        passExamScore: 0,
        examMaxCount: 0,
        passProgress: 75
      }
    }
  },
  methods: {
    open(isSingle, userId, data) {
      this.visible = true
      this.userId = userId
      if (isSingle) {
        this.formData = {
          courseIds: [data.courseId],
          passExamScore: data.testPassPoint,
          examMaxCount: data.examMaxCount,
          passProgress: data.finishProgass
        }
      } else {
        this.formData = {
          courseIds: data.map(item => item.courseId),
          passExamScore: 60,
          examMaxCount: 0,
          passProgress: 75
        }
      }
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.userId = null
      this.formData = {
        courseIds: [],
        passExamScore: 0,
        examMaxCount: 0,
        passProgress: 75
      }
    },
    submit() {
      this.loading = true
      let payload = this.formData.courseIds.map(item => {
        return {
          courseId: item,
          passExamScore: this.formData.passExamScore,
          examMaxCount: this.formData.examMaxCount,
          passProgress: this.formData.passProgress
        }
      })
      learn
        .updateTask(null, this.userId, payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('修改成功')
            this.$emit('on-ok')
            this.visible = false
          } else {
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getCoursePassScore() {
      //获取课程考试及格分
      courseApi.getCourseNewExams(this.formData.courseIds[0]).then(res => {
        this.formData.passExamScore = res.res[0].condition
      })
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  width: 100%;
  height: 100%;
  padding: 0 30px;
  list-style: none;
  text-align: left;
  font-size: 14px;
  color: #333;
  font-weight: 400;
  line-height: 28px;
  li {
    padding: 20px 0;
    border-bottom: 1px solid #eaedf1;
  }
  li:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}
</style>
