<template>
  <Modal width="650" footer-hide v-model="getStatus" @on-cancel="close" :mask-closable="false">
    <div class="title">{{ title }}</div>
    <div class="content">
      <div style="display: flex;align-items: center;justify-content: center;" v-if="Isloading">
        <Spin size="large" />
      </div>
      <div v-else>
        <vueScroll :ops="ops">
          <Collapse style="max-height: 200px" accordion>
            <Panel class="panelStyle" v-for="(course, index) in courseData" :key="index" style="position:relative;">
              <span>第{{ index + 1 }}章</span>
              {{ course.title }}
              <span class="fSpanStyle" style="position: absolute;right: 15px;top: 6px;" v-if="course.progress">
                学习进度{{ (course.progress * 100).toFixed(2) || 0 }}%
              </span>
              <span class="fSpanStyle" style="position: absolute;right: 15px;top: 6px;" v-else>学习进度0.00%</span>
              <template v-if="course.hasOwnProperty('children') && course.children.length > 0">
                <p
                  class="pStyle"
                  v-for="(child, num) in course.children"
                  style="position: relative;padding-left: 25px;margin-bottom: 15px;"
                  :key="num"
                  slot="content"
                >
                  {{ child.title
                  }}<span v-if="course.progress" class="sSpanStyle" style="position: absolute;right: 0">学习进度{{ percent(child) }}%</span>
                  <span v-else>学习进度 0.00%</span>
                </p>
              </template>
            </Panel>
          </Collapse>
        </vueScroll>
        <hr class="hrStyle" />
        <ul>
          <li v-for="(exam, index) in examList" :key="index">
            <div @click="seeExam(exam)" style="cursor: pointer;">
              <span>{{ exam.title }}</span>
              <span class="point">{{ exam.hPoint }}</span>
              <span :style="exam.isPass ? 'color: #5ABC50' : 'color: red'">{{ exam.isPass ? '已通过' : '未通过' }}</span>
              <Button :disabled="exam.examId === 0" type="primary" @click.stop="getPersonExam(exam)">查看答题详情</Button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
      <Button @click="close">取消</Button>
      <Button type="primary" @click="close">确定</Button>
    </div>
  </Modal>
</template>

<script>
import vueScroll from 'vuescroll'
import course from '../../../../api/course'
import learn from '../../../../api/learnTask'
import examApi from '../../../../api/exam'
export default {
  name: 'personTask',
  components: { vueScroll },
  props: {
    status: {
      type: Boolean,
      default: false
    },
    courseId: {
      type: Number,
      default: 0
    },
    userId: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '前端工程基础'
    }
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 260
        },
        rail: {
          // size: '1px' // 滚动条大小
        },
        bar: {
          background: '#333',
          opacity: 0.6, // 滚动条透明度
          onlyShowBarOnScroll: false, // 是否只在滚动时bar
          showDelay: 0 // 鼠标离开该区域多长时间隐藏
        }
      },
      courseData: [],
      children: [],
      num: '1',
      examList: [],
      getStatus: this.status,
      timer: 0,
      Isloading: true
    }
  },
  computed: {
    percent() {
      return function(child) {
        let progress = child && true && true && child.progass <= 1 ? child.progass : 1
        return Math.round(progress * 10000) / 100 || 0
      }
    }
  },
  mounted() {
    this.getChapterByCourseId()
  },
  methods: {
    getChapterByCourseId() {
      this.courseData = []
      course.getChapterByCourseId(this.courseId, this.userId).then(res => {
        const chapter = res.res.chapters
        this.timer = setTimeout(() => {
          chapter.map(c => {
            c.sections.map(s => {
              s.title = s.name
              s.progass = s.maxProgress
            })
            this.courseData.push({
              chapterId: c.chapterId,
              title: c.name,
              progress: c.maxProgress,
              children: c.sections
            })
          })
          this.getCourseExamByCourseId()
        }, 1000)
      })
    },
    getCourseExamByCourseId() {
      learn.getTaskExamByCourseIdAndUserId(Number(this.userId), this.courseId).then(res => {
        const e = res.res
        this.examList.push({
          examId: e.examId,
          title: e.examName,
          hPoint: e.highestScore,
          isPass: e.passed
        })
        this.Isloading = false
      })
    },
    getStudyProgass(course) {
      let data = course.children
      let prograss = 0
      if (data) {
        data.forEach(d => {
          prograss += d.progress || 0
        })
      }
      return prograss * 100
    },
    close() {
      this.getStatus = false
      this.$emit('on-cancel', this.getStatus)
    },
    seeMyAnswer(examId) {
      this.$router.push({
        path: '/manage/study/task/answer',
        query: {
          IsAnswerExam: true,
          examId: examId,
          userId: Number(this.userId)
        }
      })
    },
    getPersonExam(exam) {
      // let examId = 646
      // let userId = 6
      let userId = this.userId
      const examId = exam.examId
      examApi.getPersonExamByExamId(userId, examId).then(res => {
        if (res.res.length === 0) {
          this.$message.warning('暂无考试记录')
        } else {
          this.seeMyAnswer(examId)
        }
      })
    },
    seeExam(exam) {
      const examId = exam.examId
      if (exam.examId === 0) {
        this.$message.warning('暂无试卷')
        return
      }
      this.$router.push({
        path: '/manage/study/task/answer',
        query: {
          IsAnswerExam: false,
          examId: examId,
          userId: Number(this.userId)
        }
      })
    }
  },
  destroyed() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less" scoped>
.cssCenter {
  display: flex;
  align-items: center;
}

.title {
  /*height: 35px;*/
  text-align: center;
  color: #3a4e64;
  font-size: 18px;
  line-height: 42px;
  font-weight: bold;
  margin-bottom: 31px;
}

.content {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: #333;

  .fSpanStyle {
    color: #3c88d3;
  }

  .sSpanStyle {
    color: #3c88d3;
  }

  .pStyle {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  .pStyle:first-child {
    margin-top: 15px;
  }

  ::v-deep .ivu-collapse {
    background: none;
    border: none;
  }

  ::v-deep .ivu-collapse-item {
    border: none;
  }

  .hrStyle {
    margin: 20px 0 10px 0;
    background: #eaedf1;
    height: 1px;
    border: none;
  }

  ul {
    list-style: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;

    li {
      position: relative;
      padding: 0 0 0 20px;
      margin-bottom: 25px;
      font-size: 12px;

      span {
        margin-right: 30px;
      }

      span:first-child {
        font-size: 14px;
        color: #333;
      }

      .point {
        color: #666;
      }

      button {
        position: absolute;
        right: 15px;
        /*width: 76px;*/
        height: 30px;
        font-size: 12px;
        background: #6a8abe;
        color: #fffefe;
        border-radius: 4px;
        box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.3);
      }
    }
  }
}

.footer {
  height: 40px;
  text-align: center;
  margin: 97px 0 25px 0;

  button {
    width: 90px;
    height: 36px;
  }

  button:first-child {
    margin-right: 20px;
  }
}
</style>
