<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>学习管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/study/task' }">学习任务</el-breadcrumb-item>
        <el-breadcrumb-item>任务详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-button @click="addLTask" type="primary" size="small" style="width: 160px">
          <div>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xinzeng" />
            </svg>
            <p>新增学习任务</p>
          </div>
        </el-button>
        <el-button :disabled="isDisabled" size="small" @click="batchDeleteTask">
          <div>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shanchu" />
            </svg>
            <p>删除</p>
          </div>
        </el-button>
        <el-button :disabled="isDisabled" size="small" style="width: 160px" @click="updateCondition">
          <div>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-bianji" />
            </svg>
            <p>修改完成任务条件</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <el-table
          class="yt-table"
          @selection-change="selectTask"
          :data="taskList"
          v-loading="tableLoading"
          height="calc(100% - 35px)"
          @row-click="seeDetail"
        >
          <el-table-column width="31" type="selection" align="center" />
          <el-table-column label="课程名称" prop="courseName" show-overflow-tooltip />
          <el-table-column label="完成条件(学习百分比)" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <span>{{ row.finishProgass }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="及格线" prop="testPassPoint" show-overflow-tooltip />
          <el-table-column label="测试分数" prop="testPoint" show-overflow-tooltip />
          <el-table-column label="最大次数" prop="examMaxCount" show-overflow-tooltip />
          <el-table-column label="学习进度" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div :class="row.learnProgass >= 60 ? 'finLearn' : 'noLearn'">{{ (row.learnProgass * 100).toFixed(2) }}%</div>
            </template>
          </el-table-column>
          <el-table-column label="学习状态" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div>
                <Tag type="dot" :color="learnStatus[row.learnStatus].color">
                  {{ learnStatus[row.learnStatus].text }}
                </Tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="上次学习时间" prop="courseName" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div>{{ row.lastLearnTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="任务创建者" prop="creator" show-overflow-tooltip />
          <el-table-column label="设置日期" prop="setTime" show-overflow-tooltip />
          <el-table-column label="操作" width="80px">
            <template slot-scope="{ row }">
              <div class="iconStyle">
                <Tooltip content="编辑" :transfer="true">
                  <svg class="icon" aria-hidden="true" @click.stop="updateTaskFinish(row)">
                    <use xlink:href="#icon-a-bianji" />
                  </svg>
                </Tooltip>
                <Tooltip content="删除" :transfer="true">
                  <svg class="icon" aria-hidden="true" @click.stop="deleteTask(row)">
                    <use xlink:href="#icon-shanchu" />
                  </svg>
                </Tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (taskTotal.toString().length > 1 ? taskTotal.toString().length : 2)"
          show-sizer
          show-elevator
          show-total
          :page-size-opts="[10, 20, 40, 100]"
          :total="taskTotal"
          @on-page-size-change="changeSize"
          @on-change="handlePageNum"
          :current="pageNumber + 1"
        />
      </div>
    </div>
    <UpdateTask ref="updateTask" @on-ok="getAllTaskByUserId" />
    <div v-if="seeDetailStatus">
      <personTask :title="titleCN" :courseId="rowCourseId" :userId="userId" :status="seeDetailStatus" @on-cancel="closeP" />
    </div>
    <ConfirmDialog ref="deleteDialog" :title="'删除学习任务'" @on-ok="delSelectedTask">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除学习任务？
      </div>
    </ConfirmDialog>
    <learnTask ref="learnTask" :title="setTitle" @refresh="getAllTaskByUserId" />
  </div>
</template>

<script>
import learnTask from '../type/learnTask'
import UpdateTask from '../type/UpdateTask'
import personTask from '../type/personTask'
import learn from '../../../../api/learnTask'
import YTIcon from '@components/common/YTIcon'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
export default {
  name: 'taskDetail',
  components: { UpdateTask, personTask, YTIcon, ConfirmDialog, learnTask },
  data() {
    return {
      taskList: [],
      taskTotal: 0,
      pageNumber: 0,
      pageSize: 10,
      toSetTask: false,
      seeDetailStatus: false,
      userId: null,
      operatorId: 0,
      courseByIds: [],
      isDisabled: true,
      setTitle: '',
      titleCN: '',
      rowCourseId: 0,
      row: null,
      learnStatus: [
        { color: 'default', text: '未学习' },
        { color: 'primary', text: '学习中' },
        { color: 'success', text: '已完成' }
      ],
      tableLoading: false
    }
  },
  created() {
    this.userId = this.$handleParams('taskDetailData').userId
  },
  mounted() {
    this.getAllTaskByUserId()
  },
  methods: {
    updateCondition() {
      // 批量设置学习任务
      let isSingle = this.courseByIds.length === 1
      this.$refs.updateTask.open(isSingle, this.userId, isSingle ? this.courseByIds[0] : this.courseByIds)
    },
    getTime(time) {
      //将时间戳转换成时间格式
      let date = new Date(time)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      date = year + '-' + month + '-' + day
      return date
    },
    changeSize(val) {
      this.pageSize = val
      this.getAllTaskByUserId()
    },
    handlePageNum(page) {
      this.pageNumber = page - 1
      this.getAllTaskByUserId()
    },
    getAllTaskByUserId() {
      this.taskList = []
      this.tableLoading = true
      const form = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      }
      learn
        .getAllTaskByUserId(form, this.userId)
        .then(res => {
          this.taskTotal = res.res.total
          const list = res.res.data
          list.map(l => {
            this.taskList.push({
              id: l.id,
              courseId: l.courseId,
              courseName: l.courseName,
              finishProgass: l.passProgress,
              examMaxCount: l.examMaxCount,
              testPassPoint: l.passExamScore,
              testPoint: l.hasOwnProperty('currentExamScore') ? l.currentExamScore : '暂无分数',
              learnProgass: l.courseProgress,
              creator: l.creatorName,
              learnStatus: l.status,
              setTime: this.getTime(l.createTime),
              lastLearnTime: l.recentStudyTime ? this.getTime(l.recentStudyTime) : '暂未学习'
            })
          })
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    updateTaskFinish(row) {
      this.$refs.updateTask.open(true, this.userId, row)
    },
    closeP(data) {
      this.seeDetailStatus = data
    },
    selectTask(row) {
      this.courseByIds = row
      this.isDisabled = this.courseByIds.length === 0
    },
    seeDetail(row) {
      this.titleCN = row.courseName
      this.rowCourseId = row.courseId
      this.seeDetailStatus = true
    },
    deleteTask(row) {
      this.courseByIds = []
      this.courseByIds.push(row)
      this.$refs.deleteDialog.open()
    },
    batchDeleteTask() {
      this.$refs.deleteDialog.open()
    },
    delSelectedTask() {
      const deleteStudyTaskListDTO = {
        deleteStudyTaskDTOS: [],
        operatorId: this.operatorId
      }
      this.courseByIds.map(c => {
        deleteStudyTaskListDTO.deleteStudyTaskDTOS.push({
          courseIds: [c.courseId],
          userId: Number(this.userId)
        })
      })
      learn.batachDelTask(deleteStudyTaskListDTO).then(res => {
        if (res.res) {
          this.$message.success('删除成功')
          this.getAllTaskByUserId()
        }
      })
    },
    addLTask() {
      //新增
      this.$refs.learnTask.open('user', null, [this.userId])
    }
  }
}
</script>

<style lang="less" scoped>
.centerStyle {
  display: flex;
  align-items: center;
}
.title {
  height: 62px;
  box-shadow: 0 0 5px rgba(7, 27, 41, 0.1);
  margin-bottom: 12px;
  button {
    font-size: 14px;
    line-height: 25px;
    height: 40px;
    margin-right: 15px;
  }
}
.taskTable {
  cursor: pointer;
  .finLearn {
    color: lawngreen;
  }
  .noLearn {
    color: red;
  }
  ::v-deep .ivu-table-body span {
    font-size: 12px;
    /*line-height: 25px;*/
    color: #5e6470;
    opacity: 1;
    white-space: nowrap;
  }
  ::v-deep .ivu-table-header span {
    font-size: 14px;
    /*line-height: 28px;*/
    color: #3a4e64;
    font-weight: 400;
  }
}
.iconStyle {
  font-size: 15px;
  .icon {
    cursor: pointer;
    /*color: #bdc3cb;*/
  }
  svg:first-child {
    margin-right: 5px;
  }
}
::v-deep .ivu-tag-dot {
  border: none !important;
  background: none !important;
  padding: 0;
}
</style>
